// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-section-1-js": () => import("./../src/pages/Section1.js" /* webpackChunkName: "component---src-pages-section-1-js" */),
  "component---src-pages-section-2-js": () => import("./../src/pages/Section2.js" /* webpackChunkName: "component---src-pages-section-2-js" */)
}

